//each exported class instance contains an array of functions to run every time a sepcified event is fired
//this consolidates various different event listeners into just one, which is neat

class EventsService {
    constructor(event: string, element?: any) {
        this.event = event;
        if (!!element) {
            if (!!element.addEventListener && !!element.removeEventListener) {
                this.element = element;
            }
            else {
                console.error('Events service specified element does not support event listener');
            }
        }
        else if (typeof window !== 'undefined') {
            this.element = window;
        }
    }
    private onEvents: (() => any)[] = [];
    private listening: boolean = false;
    private event: string = '';
    private element;

    public add(onEvent: () => void) {
        this.onEvents.push(onEvent);
        onEvent();
        if (!this.listening && this.element) {
            this.listening = true;
            this.onEventBound = this.onEvent.bind(this);
            this.element.addEventListener(this.event, this.onEventBound, { passive: true });
        }
    }

    public stop(onEvent: () => void) {
        let newarray = [];
        this.onEvents.forEach((e, i) => {
            if (e !== onEvent) {
                newarray.push(e)
            }
        })
        this.onEvents = newarray;
        if (!this.onEvents.length && this.listening) {
            this.listening = false;
            this.element.removeEventListener(this.event, this.onEventBound);
        }
    }

    private onEvent() {
        if (!!this.onEvents && !!this.onEvents.length) {
            this.onEvents.forEach(onEvent => onEvent());
        }
    }

    private onEventBound;
}

//events go here

export const onScrollService = new EventsService('scroll');
export const onResizeService = new EventsService('resize');