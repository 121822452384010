import React from 'react'
import { DistanceFromCentre } from './distanceFromCentre';

interface IParallaxProps extends React.Props<HTMLDivElement> {
  parallaxAmount?: number
  children
  className?: string
  scale?: number
}

const Parallax: React.FunctionComponent<IParallaxProps> = (props) => {
  const { children, parallaxAmount, className, scale, ...attr } = props;
  return (
    <DistanceFromCentre>
      {({ distance, element }) =>
        <div
          className={`parallax${!!className ? ` ${className}` : ''}`}
          ref={element}
          style={{ transform: `translateY(${distance * parallaxAmount}px) scale(${scale})` }}
          {...attr}
        >
          {children}
        </div>
      }
    </DistanceFromCentre>
  )
}

Parallax.defaultProps = {
  parallaxAmount: -0.4,
  scale: 1.1
}

export default Parallax