import * as React from 'react'
import { graphql, Link } from 'gatsby';

import Layout from '../components/layout/layout'

import * as moment from 'moment';
import { ClubNightMini } from '../components/data/clubNightMini';

import './clubnights.scss';
import { IClubNightEdges } from '../typings';
import Parallax from '../components/visual/parallax';

interface IProps {
  data?: { clubNights: IClubNightEdges };
}

export const ClubNightsPage: React.FunctionComponent<IProps> = props => {
  const { clubNights } = props.data;
  return (
    <Layout className='page club-nights-page'>
      <Parallax parallaxAmount={-0.1}>
        <h3>Club Nights</h3>
      </Parallax>
      <div className="club-nights-list">
        {!!clubNights && clubNights.edges.length && clubNights.edges.map((clubNight, i) =>
          <>
            {(i == 0 || moment(clubNight.node.date).year() !== moment(clubNights.edges[i - 1].node.date).year()) ?
              <h3>{moment(clubNight.node.date).format('YYYY')}</h3>
              : ''
            }
            <ClubNightMini clubNight={clubNight.node} key={clubNight.node.title + i} />
          </>
        )}
      </div>
    </Layout>
  )
}

export default ClubNightsPage

export const query = graphql`
query ClubNightsQuery{
  clubNights: allMarkdownRemark(
      filter: { collectionKey: { eq: "clubNights" } },
      sort: { order: DESC, fields: [date] },
    ){
    edges
    {
      node
      {
        title
        date
        location
        collectionKey
        backgroundSmall
        # artistsReferences{
        #   title
        # }
        fields
        {
          path
        }
      }
    }
  }
}`
