import * as React from 'react'

interface ILazyImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  rootMargin?: string;
}

const LazyImage: React.FunctionComponent<ILazyImageProps> = (props) => {
  const {rootMargin, src, ...attrs} = props;

  let observer: IntersectionObserver;
  let element = React.useRef(null);

  React.useEffect(()=>{
    observer = new IntersectionObserver(
      (entries, observer) => {
        let entry = entries[0];
        if (entry.isIntersecting) {
          element.current.setAttribute('src', src);
          observer.unobserve(element.current);
        }
      },
      {
        rootMargin: rootMargin,
        threshold: 1
      }
    );
    observer.observe(element.current);
    return (()=>{
      observer.unobserve(element.current);
    })
  })

  return <img
    {...attrs}
    ref={element}
  />
}

LazyImage.defaultProps = {
  rootMargin: '500px'
}

export default LazyImage;