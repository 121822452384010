import * as React from 'react'
import * as ReactDOM from 'react-dom'
import * as moment from 'moment';
import { Link } from 'gatsby';
import { getLink } from '../../helpers/linkHelper';
import './clubNightMini.scss';
import { IClubNight } from '../../typings';
import LazyImage from '../visual/lazyImage';

interface IProps extends React.Props<HTMLElement> {
  clubNight: IClubNight;
  id?: any;
}

interface IState {
}

export class ClubNightMini extends React.Component<IProps, IState>{
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  public elem: HTMLElement;
  static defaultProps = {
  }
  render() {
    const { clubNight, id } = this.props;
    const artists = clubNight.artistsReferences;
    return (
      <Link to={getLink(clubNight.collectionKey, clubNight.title)}>
        <div className='club-night-mini' id={id}>
          <div className='club-night-mini-image'>
            <LazyImage src={!!clubNight.backgroundSmall ? clubNight.backgroundSmall : require('../../assets/brand/logo.jpg')} />
            <div className="club-night-mini-artists">
              {(!!artists && !!artists.length) ?
                <>
                  {artists.slice(0, 4).map((artist, i) =>
                    (<p key={clubNight.title + 'artist' + i}>{artist.title}</p>)
                  )}
                  {artists.length > 4 && <p><i>and more...</i></p>}
                </>
                :
                <p>Line up TBA...</p>
              }
            </div>
          </div>
          <p className='club-night-location'>{clubNight.location}</p>
          <p className='club-night-date'><i>{moment(clubNight.date).format('Do MMMM YYYY')}</i></p>
        </div>
      </Link>
    )
  }
}